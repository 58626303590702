jQuery.noConflict();
(function( $ ) {
	$( function() {
		
		$( document ).ready( function() {
			
			// no-js
			var html = $( 'html' );
			if ( html.hasClass( 'no-js' ) ) {
				html.removeClass( 'no-js' );
				html.addClass( 'js' );
			}
			
			// page load
			var main = $( '.main' );
			if ( main.length ) {
			
				devplus_init( main );
				
			}
			
		} );
		
		function devplus_init( main ) {
			
			// loaded
			main.addClass( 'loaded' );
			
		}
		
	} );
})( jQuery );